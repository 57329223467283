import { Component, ElementRef, Input, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { NamedFormControl } from '@navigatingart/named-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { FormFieldBaseComponent } from '../form-field-base.component';

/**
 * Form field toggle component
 */
@UntilDestroy()
@Component({
  selector: 'navigatingart-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  standalone: true,
  imports: [MatSlideToggle, ReactiveFormsModule, TranslateModule],
})
export class ToggleComponent extends FormFieldBaseComponent implements OnInit {
  private elementRef = inject(ElementRef);

  @Input()
  override control!: NamedFormControl;

  override ngOnInit() {
    super.ngOnInit();
    this.control.valueChanges.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe({
      next: () => this.elementRef.nativeElement.dispatchEvent(new FocusEvent('focusout', { bubbles: true })),
    });
  }
}

<button
  #menuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="columnsMenu"
  [matTooltip]="'DataTableComponent.button.setColumns.tooltip' | translate"
  mat-icon-button
  type="button"
  class="set-columns"
  (menuOpened)="onMenuOpened()"
>
  <mat-icon>more_vert</mat-icon>
</button>

<mat-menu #columnsMenu="matMenu">
  @for (column of columnMenuOptions; track column) {
    <div mat-menu-item (click)="onCheckboxChanged(column.name); $event.stopPropagation()">
      <mat-checkbox color="primary" [value]="column.name" [checked]="selected?.includes(column.name)!">
        {{ column.label || column.name | translate }}
      </mat-checkbox>
    </div>
  }
  <button mat-button type="button" (click)="onSave()">
    {{ 'Components.button.save.label' | translate }}
  </button>
</mat-menu>

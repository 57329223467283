<mat-form-field [floatLabel]="floatLabel">
  <mat-label [translate]="label"></mat-label>
  <span matPrefix> </span>
  <span matSuffix> </span>
  @if (enableMarkdown) {
    <div
      markdown
      [class.na-hidden]="active || !control.value"
      class="na-markdown"
      (click)="activateInput()"
      [data]="control.value"
    ></div>
  }

  <textarea
    [class.na-hidden]="enableMarkdown && !active && control.value"
    (blur)="onBlur()"
    (focus)="activateInput()"
    [formControl]="$any(control)"
    [cdkAutosizeMaxRows]="+maxRows"
    [cdkAutosizeMinRows]="+minRows"
    [matTooltip]="hint | translate"
    [name]="control.name"
    [placeholder]="label | translate"
    [required]="$any(control).required"
    matInput
    cdkTextareaAutosize
    [readonly]="readonly"
  >
  </textarea>

  @for (errorName of control.errors | keyvalue; track errorName) {
    <mat-error [translate]="errorLabel(errorName.key)"> </mat-error>
  }

  @if (hintStart) {
    <mat-hint align="start">{{ hintStart }}</mat-hint>
  }
  @if (hintEnd) {
    <mat-hint align="end">{{ hintEnd }}</mat-hint>
  }
</mat-form-field>

@if (collapsed === true) {
  <a type="button" (click)="collapsed = false" translate="Components.button.showMore.label"> </a>
}
@if (collapsed === false) {
  <a type="button" (click)="collapsed = true" translate="Components.button.showLess.label"> </a>
}

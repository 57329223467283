<mat-form-field [floatLabel]="floatLabel">
  <mat-label [translate]="label"></mat-label>
  <mat-select [formControl]="control" [placeholder]="label | translate" [required]="control.required">
    @if (iconConfig && selectedOption) {
      <mat-select-trigger>
        <i [ngClass]="$any(selectedOption.icon)"></i>
        <span>{{ displayFn(selectedOption.key) | translate }}</span>
      </mat-select-trigger>
    }

    @if (allowReset) {
      <mat-option [value]="resetValue">
        {{ 'FormFieldEnumComponent.text.none' | translate }}
      </mat-option>
    }

    @for (o of options; track o) {
      <mat-option
        [disabled]="
          disableSettings?.disableFn && disableSettings?.disableFn($any(o.key), disableSettings.disabledOptions)
        "
        [value]="o.value"
      >
        <span>{{ displayFn(o.key) | translate }}</span>
      </mat-option>
    }
  </mat-select>

  @for (errorName of control.errors | keyvalue; track errorName) {
    <mat-error [translate]="errorLabel(errorName.key)"></mat-error>
  }
</mat-form-field>

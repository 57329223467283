<mat-form-field [floatLabel]="floatLabel">
  <mat-label [translate]="label"></mat-label>
  <mat-select
    [matTooltip]="hint | translate"
    [required]="control.required || required"
    [placeholder]="label | translate"
    [formControl]="displayControl"
  >
    @if (allowReset) {
      <mat-option [value]="resetValue">
        {{ 'FormFieldEnumComponent.text.none' | translate }}
      </mat-option>
    }

    @for (option of options; track option) {
      <mat-option [value]="option">
        {{ displayFn(option) | translate }}
      </mat-option>
    }
  </mat-select>
  @for (errorName of control.errors | keyvalue; track errorName) {
    <mat-error [translate]="errorLabel(errorName.key)"> </mat-error>
  }
  @for (errorName of displayControl.errors | keyvalue; track errorName) {
    <mat-error [translate]="errorLabel(errorName.key)"> </mat-error>
  }
</mat-form-field>

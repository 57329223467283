import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckbox, MatCheckboxDefaultOptions } from '@angular/material/checkbox';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { DataTableComponentEntityType, IDataTableColumnConfig, IDataTableConfig } from '../../data-table.interfaces';

@Component({
  selector: 'navigatingart-data-table-column-menu',
  templateUrl: './data-table-column-menu.component.html',
  viewProviders: [
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions,
    },
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconButton,
    MatMenuTrigger,
    MatTooltip,
    MatIcon,
    MatMenu,
    MatMenuItem,
    MatCheckbox,
    MatButton,
    TranslateModule,
  ],
})
export class DataTableColumnMenuComponent<T extends DataTableComponentEntityType> {
  @Input()
  config!: IDataTableConfig<T>;

  @Input()
  displayableColumns: IDataTableColumnConfig<T>[] | null = null;

  @Input()
  visibleColumns: string[] | undefined;

  @Output()
  columnsChanged = new EventEmitter<string[]>();

  selected: string[] | undefined;

  columnMenuOptions: IDataTableColumnConfig<T>[] | undefined;

  onCheckboxChanged(changedColumn: string): void {
    let newColumnSelection: string[] = this.selected || [];
    const isChecked = (this.selected || []).includes(changedColumn);
    if (!isChecked) {
      newColumnSelection = newColumnSelection.concat(changedColumn);
    } else {
      newColumnSelection = newColumnSelection.filter((s) => s !== changedColumn);
    }
    this.selected = newColumnSelection;
  }

  onSave(): void {
    this.columnsChanged.emit(this.selected);
  }

  onMenuOpened(): void {
    this.columnMenuOptions = this.displayableColumns?.filter(
      (column: IDataTableColumnConfig<T>) => !column.hideInColumnMenu,
    );
    this.selected = this.visibleColumns;
  }
}

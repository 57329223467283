@if (query.ui$ | async; as ui) {
  @if (query.isLoading$ | async) {
    <navigatingart-loading-indicator></navigatingart-loading-indicator>
  }
  @if (config.showRecents) {
    @if (ui.recents; as recents) {
      @if (recents.length) {
        <div class="recents">
          <h6>{{ 'DataTableComponent.recentSelections.label' | translate }}</h6>
          @for (row of recents; track row) {
            <div>
              @if (config.selection === 'single') {
                <mat-radio-button
                  [checked]="isRecentSelected(row)"
                  (change)="onActiveChangedRecents(row)"
                  color="primary"
                  name="select"
                >
                </mat-radio-button>
              } @else {
                <mat-checkbox
                  color="primary"
                  [checked]="isRecentSelected(row)"
                  [indeterminate]="false"
                  (change)="onActiveChangedRecents(row)"
                >
                </mat-checkbox>
              }
              <span [innerHTML]="config.renderRecent!(row)"></span>
            </div>
          }
        </div>
      }
    }
  }
  @if (config.header?.showFilters !== false) {
    <navigatingart-data-table-filters [config]="config" [service]="service" [query]="query">
    </navigatingart-data-table-filters>
  }
  <ng-content></ng-content>
  <div class="paginator-wrapper">
    <div class="clear-button-wrapper">
      @if (config.header?.showFilters !== false) {
        @if (query.activeFilterCount$ | async; as filterCount) {
          <button
            type="button"
            (click)="service.clearFilters()"
            matTooltip="{{ 'Components.button.filter.clear.tooltip' | translate }}"
            mat-button
            color="primary"
          >
            {{ 'Components.button.filter.clear.label' | translate }}
          </button>
        }
      }
    </div>
    <div class="na-settings-container">
      @if (config.gridEnabled) {
        <div class="na-switch-view-container">
          <button
            class="na-switch-view-btn"
            [ngClass]="ui.viewMode !== viewOptions.GRID ? 'primary-color' : 'accent-color'"
            (click)="onViewModeChanged(viewOptions.GRID)"
            type="button"
            mat-button
          >
            <span class="mat-caption">
              <i class="icon-grid"></i>
              {{ 'DataTableComponent.gridView.label' | translate }}
            </span>
          </button>
          <button
            class="na-switch-view-btn"
            [ngClass]="ui.viewMode !== viewOptions.TABLE ? 'primary-color' : 'accent-color'"
            (click)="onViewModeChanged(viewOptions.TABLE)"
            type="button"
            mat-button
          >
            <span class="mat-caption">
              <i class="icon-column"></i>
              {{ 'DataTableComponent.tableView.label' | translate }}
            </span>
          </button>
        </div>
      }
      @if (config.header?.showPagination !== false) {
        <mat-paginator
          [length]="query.total$ | async"
          [pageIndex]="ui.renderState.pagination.pageIndex"
          [pageSize]="ui.renderState.pagination.pageSize"
          [pageSizeOptions]="pageSizeOptions"
          (page)="onPaginate($event)"
          [class.na-grid-enabled]="ui.viewMode === viewOptions.GRID"
        >
        </mat-paginator>
      }
    </div>
  </div>
  @if (ui.viewMode === viewOptions.TABLE) {
    <mat-table
      [class.is-loading]="query.isLoading$ | async"
      matSort
      [dataSource]="query.entities$"
      [trackBy]="datasourceTrackById.bind(this)"
      (matSortChange)="onSortChange($event)"
      [matSortActive]="ui.renderState.sort?.active!"
      [matSortDirection]="ui.renderState.sort?.direction!"
    >
      @if (config.selection) {
        <ng-container matColumnDef="selection">
          <mat-header-cell *matHeaderCellDef> </mat-header-cell>
          <mat-cell *matCellDef="let row">
            @let isDisabled = config.disableRowSelection !== undefined && config.disableRowSelection(row) === true;
            <div [matTooltip]="config.selectInputTooltip ? config.selectInputTooltip(row) : undefined">
              @if (config.selection === 'single') {
                <mat-radio-button
                  [disabled]="isDisabled"
                  [checked]="isActive(row)"
                  (change)="onActiveChanged(row)"
                  color="primary"
                  name="select"
                />
              } @else {
                <mat-checkbox
                  color="primary"
                  [disabled]="isDisabled"
                  [checked]="isActive(row)"
                  [indeterminate]="false"
                  (click)="$event.stopPropagation()"
                  (change)="onActiveChanged(row)"
                />
              }
            </div>
          </mat-cell>
        </ng-container>
      }
      <ng-container matColumnDef="actions">
        <mat-header-cell [class.na-hide-actions]="!config.actions && !config.columnMenu" *matHeaderCellDef>
          @if (config.columnMenu !== false) {
            <navigatingart-data-table-column-menu
              [config]="$any(config)"
              [displayableColumns]="query.displayableColumns$ | async"
              [visibleColumns]="(query.visibleColumnNames$ | async)!"
              (columnsChanged)="onColumnMenuChanged($event)"
            ></navigatingart-data-table-column-menu>
          }
        </mat-header-cell>
        <mat-cell [class.na-hide-actions]="!config.actions && !config.columnMenu" *matCellDef="let row">
          @for (button of config?.actions; track button) {
            @if (button.click) {
              <button
                type="button"
                [matTooltip]="button?.tooltip"
                [disabled]="disabled"
                (click)="button.click(row); $event.stopPropagation()"
                color="primary"
                mat-icon-button
              >
                <mat-icon inline="true">{{ button.icon }}</mat-icon>
              </button>
            }
            @if (button.emit) {
              <button
                type="button"
                [matTooltip]="button?.tooltip"
                [disabled]="disabled"
                (click)="actionPerformed.emit({ action: button.emit, row: row })"
                color="primary"
                mat-icon-button
              >
                <ng-container>
                  @if (button.icon) {
                    <mat-icon inline="true">{{ button.icon }}</mat-icon>
                  }
                  @if (!button.icon) {
                    <span>{{ button.label | translate }}</span>
                  }
                </ng-container>
              </button>
            }
            @if (button.link) {
              <a
                [matTooltip]="button?.tooltip"
                type="button"
                [disabled]="disabled"
                [routerLink]="button.link(row)"
                color="primary"
                mat-icon-button
              >
                <mat-icon inline="true">{{ button.icon }}</mat-icon>
              </a>
            }
          }
        </mat-cell>
      </ng-container>
      @for (column of query.displayableColumns$ | async; track column.name) {
        <ng-container [matColumnDef]="column.name">
          <!-- Header cell -->
          <mat-header-cell *matHeaderCellDef>
            <div>
              @if (isFirstOfGroup(column)) {
                <p class="na-group-label">
                  {{ $any(groupLabel(column)) | translate }}
                </p>
              }
              @if (column.sortable) {
                <div [class.grouped-header]="isFirstOfGroup(column)" mat-sort-header>
                  {{ column.label || column.name | translate }}
                </div>
              } @else {
                {{ column.label || column.name | translate }}
              }
            </div>
          </mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            [class.is-grouped]="isGrouped(column)"
            [class.is-sorted]="column.name === ui.renderState.sort?.active"
          >
            <navigatingart-data-table-cell
              [config]="column"
              [row]="element"
              [service]="service"
            ></navigatingart-data-table-cell>
          </mat-cell>
        </ng-container>
      }
      @if (query.renderColumns$ | async; as renderColumns) {
        @if (config.header?.showHeaderCells !== false) {
          <mat-header-row *matHeaderRowDef="renderColumns"></mat-header-row>
        }
        <mat-row
          *matRowDef="let row; columns: renderColumns"
          (click)="onActiveChanged(row)"
          (dblclick)="navigateToRowLink(row)"
          [ngClass]="$any(rowClass(row))"
          [class.selected]="selectableRow && selectedRow === row.id"
        >
        </mat-row>
      }
    </mat-table>
  } @else {
    @if (query.entities$ | async; as entities) {
      <div class="na-datatable-grid-container">
        @for (entity of entities; track entity) {
          <div
            class="na-datable-grid-item"
            (click)="config.selection ? onActiveChanged(entity) : navigateToRowLink(entity)"
          >
            @if (config.selection) {
              <div class="na-grid-item-select">
                @if (config.selection === 'multi') {
                  <mat-checkbox
                    color="primary"
                    class="na-checkbox-white"
                    [checked]="isActive(entity)"
                    (click)="$event.stopPropagation()"
                    [disabled]="disabled"
                    (change)="onActiveChanged(entity)"
                  >
                  </mat-checkbox>
                }
                @if (config.selection === 'single') {
                  <mat-radio-button
                    color="primary"
                    [checked]="isActive(entity)"
                    (click)="$event.stopPropagation()"
                    (change)="onActiveChanged(entity)"
                  >
                  </mat-radio-button>
                }
              </div>
            }
            <div class="na-datable-grid-item-info">
              @if (config.gridElementConfig?.heading; as heading) {
                <div class="na-datable-grid-item-info-text">
                  <span>{{ heading(entity) }}</span>
                </div>
              }
              @for (subText of config.gridElementConfig?.content(entity); track subText) {
                <div class="na-datable-grid-item-info-text na-grid-item-sub-text">
                  <span>{{ subText }}</span>
                </div>
              }
            </div>
            <img loading="lazy" alt="cell-image" [src]="config.gridElementConfig?.image(entity)" />
          </div>
        }
      </div>
    }
  }
  <ng-template #gridView>
    @if (query.entities$ | async; as entities) {
      <div class="na-datatable-grid-container">
        @for (entity of entities; track entity) {
          <div
            class="na-datable-grid-item"
            (click)="config.selection ? onActiveChanged(entity) : navigateToRowLink(entity)"
          >
            @if (config.selection) {
              <div class="na-grid-item-select">
                @if (config.selection === 'multi') {
                  <mat-checkbox
                    color="primary"
                    class="na-checkbox-white"
                    [checked]="isActive(entity)"
                    (click)="$event.stopPropagation()"
                    [disabled]="disabled"
                    (change)="onActiveChanged(entity)"
                  >
                  </mat-checkbox>
                }
                @if (config.selection === 'single') {
                  <mat-radio-button
                    color="primary"
                    [checked]="isActive(entity)"
                    (click)="$event.stopPropagation()"
                    (change)="onActiveChanged(entity)"
                  >
                  </mat-radio-button>
                }
              </div>
            }
            <div class="na-datable-grid-item-info">
              @if (config.gridElementConfig?.heading; as heading) {
                <div class="na-datable-grid-item-info-text">
                  <span>{{ heading(entity) }}</span>
                </div>
              }
              @for (subText of config.gridElementConfig?.content(entity); track subText) {
                <div class="na-datable-grid-item-info-text na-grid-item-sub-text">
                  <span>{{ subText }}</span>
                </div>
              }
            </div>
            <img loading="lazy" alt="cell-image" [src]="config.gridElementConfig?.image(entity)" />
          </div>
        }
      </div>
    }
  </ng-template>
  @if (query.error$ | async; as error) {
    <div class="na-error-block">
      <i class="icon-error-cat"></i>
      <h5>{{ 'Error.title.label' | translate }}</h5>
      <p>
        {{ 'Error.text.fulfill' | translate }} <br />{{ 'Error.text.fix' | translate }} <br />{{
          'Error.text.resume' | translate
        }}
      </p>
    </div>
  }
}

<mat-form-field>
  @if (label) {
    <mat-label [translate]="label"></mat-label>
  }
  <span matPrefix> </span>
  <span matSuffix> </span>
  <input
    type="text"
    [placeholder]="'AutoCompleteObjectComponent.placeholder.text' | translate"
    [attr.aria-label]="label"
    matInput
    [formControl]="displayControl"
    [matAutocomplete]="auto"
    [required]="displayControl.required || required"
  />
  <mat-autocomplete #auto="matAutocomplete">
    @for (option of autocompleteOptions$ | async; track option) {
      <mat-option [value]="option">
        {{ option }}
      </mat-option>
    }
  </mat-autocomplete>

  @for (errorName of displayControl.errors | keyvalue; track errorName) {
    <mat-error [translate]="errorLabel(errorName.key)"></mat-error>
  }

  <!-- <mat-hint *ngIf="hint" align="start">{{ hint }}</mat-hint> -->
</mat-form-field>

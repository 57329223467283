import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AsyncPipe, NgClass } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChip, MatChipGrid, MatChipInput, MatChipInputEvent, MatChipRemove } from '@angular/material/chips';
import { MatOption } from '@angular/material/core';
import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';
import { NamedFormArray, NamedFormControl } from '@navigatingart/named-forms';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { FormFieldBaseComponent } from '../form-field-base.component';

@Component({
  selector: 'navigatingart-form-field-autocomplete-chips',
  templateUrl: './autocomplete-chips.component.html',
  styleUrls: ['./autocomplete-chips.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    MatInput,
    ReactiveFormsModule,
    MatAutocompleteTrigger,
    MatChipInput,
    MatAutocomplete,
    MatOption,
    MatChipGrid,
    MatChip,
    NgClass,
    MatTooltip,
    MatIcon,
    MatChipRemove,
    AsyncPipe,
    TranslateModule,
  ],
})
export class FormFieldAutocompleteChipsComponent<T> extends FormFieldBaseComponent implements OnInit {
  @Input()
  override control!: NamedFormArray;

  @Input()
  autoCompleteFn!: (value: string) => Observable<T[]>;

  @Input()
  displayProperty: keyof T = 'text' as keyof T;

  @Input()
  placeholder = '';

  @Input()
  updateData = true;

  @Input()
  displayFn = this._defaultDisplayFn;

  @Input()
  chipWhite = true;

  autocompleteOptions$: Observable<T[]> | undefined;

  chipInputControl = new NamedFormControl('chipInput', null);

  separatorKeysCodes = [ENTER, COMMA];

  @ViewChild('chipInput', { static: true })
  chipInput!: ElementRef;

  @Output()
  chipRemoved = new EventEmitter();

  @Output()
  chipAdded = new EventEmitter();

  @Output()
  chipCreate = new EventEmitter<string>();

  @Output()
  chipClicked = new EventEmitter<MouseEvent>();

  override ngOnInit() {
    super.ngOnInit();
    this.autocompleteOptions$ = this.chipInputControl.valueChanges.pipe(
      filter((input) => typeof input === 'string'),
      switchMap((input: string) => this.autoCompleteFn(input)),
    );
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.addChip(event);
  }

  addChip(event: MatAutocompleteSelectedEvent) {
    if (this.updateData) {
      this.control.updateFromSource([...this.control.value, event.option.value]);
    }
    this.chipInput.nativeElement.value = '';
    this.chipInput.nativeElement.blur();
    this.chipAdded.emit(event.option.value);
  }

  removeChip(index: number): void {
    const item = this.control.value[index];
    if (this.updateData) {
      this.control.updateFromSource(
        this.control.value.filter((o: T, idx: number) => idx !== index),
        true,
      );
    }
    this.chipRemoved.emit(item);
  }

  _defaultDisplayFn(input: T): string {
    return input ? `${input[this.displayProperty]}` : '';
  }

  createChip(input: MatChipInputEvent): void {
    this.chipCreate.emit(input.value);
  }

  onClick(event: MouseEvent): void {
    this.chipClicked.emit(event);
  }
}

<div class="grid">
  <div class="span-xs-5">
    @if (freeTextControl) {
      <navigatingart-form-field-input
        [control]="$any(freeTextControl)"
        (focusout)="fillEDTFFromText()"
        class="flex-grow"
        [label]="customLabelText"
      >
      </navigatingart-form-field-input>
    }
  </div>

  <div class="span-xs-1 na-container-transform">
    @if (freeTextControl) {
      <button
        type="button"
        mat-icon-button
        color="primary"
        (click)="fillEDTFFromText(true)"
        matTooltip="{{ 'EdtfInputComponent.button.edtfFromText.label' | translate }}"
        [disabled]="!freeTextControl.value || freeTextControl.invalid || useTextFlagControl?.value"
      >
        <i class="icon-convert"></i>
      </button>
    }
  </div>

  <div class="span-xs-5">
    <navigatingart-form-field-input
      single
      [control]="$any(control)"
      [label]="customLabelEdtf"
    ></navigatingart-form-field-input>
    <div class="range-text mat-caption">{{ renderHint(control.value) }}</div>
  </div>
</div>

@if (useTextFlagControl) {
  <navigatingart-toggle
    matTooltip="{{ 'EdtfInputComponent.useTextFlagControl.tooltip' | translate }}"
    label="EdtfInputComponent.useTextFlagControl.label"
    [control]="$any(useTextFlagControl)"
  >
  </navigatingart-toggle>
}

import { Injectable, Input } from '@angular/core';
import { NamedFormControl } from '@navigatingart/named-forms';
import { DataTableComponentEntityType, IDataTableColumnConfig } from '../../data-table.interfaces';
import { DataTableService } from '../../data-table.service';

@Injectable() // @see https://github.com/angular/angular/issues/37769
export abstract class AbstractDataTableFilterComponent<T extends DataTableComponentEntityType> {
  @Input()
  column!: IDataTableColumnConfig<T>;

  @Input()
  control!: NamedFormControl;

  @Input()
  service!: DataTableService<T>;

  @Input()
  triggerActionFn = (): void => {
    throw new Error('triggerActionFn not implemented');
  };

  abstract clear(): void;
}

import { Component, Input, OnInit, inject } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { NamedFormControl, NamedFormControlTypes } from '@navigatingart/named-forms';
import { TranslateService } from '@ngx-translate/core';
import { FormFieldProviders } from '../constants/form-field.providers';
import { FORM_FIELD_MODULE_SETTINGS, IFormFieldModuleSettings } from '../constants/form-field.token';

@Component({
  standalone: true,
  selector: 'navigatingart-abstract-form-field-component',
  template: '',
  providers: FormFieldProviders,
})
export class FormFieldBaseComponent implements OnInit {
  formGroupDirective = inject(FormGroupDirective, { optional: true, host: true, skipSelf: true });
  readonly moduleSettings = inject<IFormFieldModuleSettings>(FORM_FIELD_MODULE_SETTINGS);
  readonly translateService? = inject(TranslateService);

  @Input()
  name!: string;

  @Input()
  control!: NamedFormControlTypes;

  @Input()
  label: string | undefined = undefined;

  @Input()
  hint!: string;

  error!: string;

  ngOnInit() {
    if (!this.control) {
      if (!this.formGroupDirective?.form) {
        throw new Error(`Either [control] must be provided or component need parent FormGroupDirective"`);
      }
      this.control = this.formGroupDirective.form.get(this.name) as NamedFormControl;
    }

    if (!this.control) {
      throw new Error(`Cannot find control with name "${this.name}"`);
    }

    // Contruct label if not given as @Input()
    if (!this.label) {
      this.label = `${this.control.name}.label`;
    } else {
      this.label = this.label.endsWith('.label') ? this.label : `${this.label}.label`;
    }

    this.hint = this.hint || this.label.replace('.label', '.hint');

    //If env is production and there is no translation provided for the hint then dont show hint by setting it empty
    if (this.moduleSettings.isProduction && this.translateService?.instant(this.hint) === this.hint) {
      this.hint = '';
    }

    this.error = this.label.replace('.label', '.error');
  }

  errorLabel(validationErrorKey: string): string {
    if (this.translateService?.instant(this.error).required) {
      return `${this.error}.${validationErrorKey}`;
    } else {
      switch (validationErrorKey) {
        case 'required':
          return 'FormFieldBaseComponent.field.generic.error.required';
        case 'invalidEdtf':
          return 'FormFieldBaseComponent.field.generic.error.invalidEdtf';
        case 'minLength':
          return 'FormFieldBaseComponent.field.generic.error.minLength';
        case 'maxLength':
          return 'FormFieldBaseComponent.field.generic.error.maxLength';
        case 'pattern':
          return 'FormFieldBaseComponent.field.generic.error.pattern';
        case 'email':
          return 'FormFieldBaseComponent.field.generic.error.email';
        default:
          return `${this.error}.${validationErrorKey}`;
      }
    }
  }

  clear() {
    this.control.reset();
  }
}

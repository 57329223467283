import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'navigatingart-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class LoadingIndicatorComponent {}

import { Injectable, inject } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PaginatorTranslatorService extends MatPaginatorIntl {
  translate = inject(TranslateService);

  constructor() {
    super();
    const translate = this.translate;

    this.getTranslations(translate);
  }

  getTranslations = (translate: any) => {
    this.itemsPerPageLabel = translate.instant('PaginatorTranslator.itemsPerPage.label');
    this.nextPageLabel = translate.instant('PaginatorTranslator.nextPage.label');
    this.previousPageLabel = translate.instant('PaginatorTranslator.previousPage.label');
  };

  override getRangeLabel = (page: number, pageSize: number, length: number): string => {
    length = Math.max(length, 0);

    if (length === 0) {
      return (
        this.translate.instant('PaginatorTranslator.results.label') +
        ' ' +
        0 +
        ' ' +
        this.translate.instant('PaginatorTranslator.of.label') +
        length
      );
    }

    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return (
      this.translate.instant('PaginatorTranslator.results.label') +
      ' ' +
      (startIndex + 1) +
      ' - ' +
      endIndex +
      ' ' +
      this.translate.instant('PaginatorTranslator.of.label') +
      ' ' +
      length
    );
  };
}

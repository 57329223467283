import { AsyncPipe } from '@angular/common';
import { Component, inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { sortBy, uniqBy } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import {
  DataTableComponentEntityType,
  DataTableFiltersSearch,
  IDataTableColumnConfig,
  IDataTableConfig,
  IDataTableSearchOption,
} from '../../data-table.interfaces';
import { DataTableService } from '../../data-table.service';
import { DataTableQuery } from '../../data-table.store';
import { DataTableFiltersInputComponent } from './data-table-filters-input.component';

type ColumnToggleMenuItem<T extends DataTableComponentEntityType> = {
  column: IDataTableColumnConfig<T>;
  disabled: boolean;
};

@Component({
  selector: 'navigatingart-data-table-filters',
  templateUrl: './data-table-filters.component.html',
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
  ],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    DataTableFiltersInputComponent,
    MatTooltip,
    MatButton,
    MatIcon,
    MatMenuTrigger,
    MatMenu,
    MatOption,
    AsyncPipe,
    TranslateModule,
  ],
})
export class DataTableFiltersComponent<T extends DataTableComponentEntityType> implements OnInit {
  private readonly translate = inject(TranslateService);

  @Input()
  config!: IDataTableConfig<T>;

  @Input()
  service!: DataTableService<T>;

  @Input()
  query!: DataTableQuery<T>;

  visibleFilters$ = new BehaviorSubject<IDataTableColumnConfig<T>[]>([]);

  columnToggleMenuItems$: Observable<ColumnToggleMenuItem<T>[]> | undefined;

  readonly numFilters = 3;

  // FIXME: Check type definition of filters
  public static transformArrayOfObjects<T extends DataTableComponentEntityType>(
    key: string,
    search: DataTableFiltersSearch<T>,
    filters: { [key: string]: [unknown] } = {},
  ) {
    const cur = filters[key] || [];
    return uniqBy(cur.concat(search.value), 'id');
  }

  public static optionsFnEnum(
    optionsEnum: readonly unknown[],
    translateFn = (val: string) => val,
  ): Observable<IDataTableSearchOption[]> {
    return of(optionsEnum).pipe(
      map((values) =>
        values.map(
          (value) =>
            ({
              value,
              translatable: translateFn(value as string),
            }) as IDataTableSearchOption,
        ),
      ),
    );
  }

  ngOnInit() {
    this._initVisibleFilters();
    this._initColumnToggleMenu();
  }

  private _initColumnToggleMenu() {
    this.columnToggleMenuItems$ = combineLatest([this.visibleFilters$, this.query.filterableColumns$]).pipe(
      map(([activeFilters, filterableColumns]) => {
        const activeNames = activeFilters.map((v) => v.name);
        const sortableColumns = filterableColumns.map((column) => ({
          ...column,
          label: this.translate.instant(column.label ?? ''),
        }));
        const defaultFilters = sortableColumns.slice(0, 3);
        const otherFilters = sortableColumns.slice(3);

        return [...defaultFilters, ...sortBy(otherFilters, ['label'], ['asc'])].map(
          (column) =>
            ({
              column,
              disabled: activeNames.includes(column.name),
            }) as ColumnToggleMenuItem<T>,
        );
      }),
    );
  }

  private _initVisibleFilters() {
    return combineLatest([this.query.filterableColumns$, this.query.activeFilters$])
      .pipe(
        first(),
        map(([filterableColumns, activeStateFilters]) => {
          const stateFilterNames: string[] = activeStateFilters ? Object.keys(activeStateFilters) : [];

          const activeFilters = stateFilterNames
            .map((key: string) => this.service.getColumnConfig(key))
            .filter((o) => o?.filter !== undefined) as IDataTableColumnConfig<T>[]; // Remove unknown filters

          const activeStateFilterNames: string[] = activeFilters.map((o) => o.name);

          if (activeFilters.length < this.numFilters) {
            const filtersToAppend = filterableColumns
              .filter((c: IDataTableColumnConfig<T>) => !activeStateFilterNames.includes(c.name))
              .slice(0, this.numFilters - activeStateFilterNames.length);
            return activeFilters.concat(filtersToAppend);
          }
          return activeFilters;
        }),
      )
      .subscribe((filters) => this.visibleFilters$.next(filters));
  }

  trackActiveFilter(column: IDataTableColumnConfig<T>) {
    return column.queryParamName ?? column.name;
  }

  onAddFilter(column: IDataTableColumnConfig<T>): void {
    this.visibleFilters$.next([...this.visibleFilters$.value, column]);
  }

  onRemoveFilter(column: IDataTableColumnConfig<T>, index?: number) {
    const visibleFilters = this.visibleFilters$.value;
    const filter = visibleFilters.find((o) => o.name === column.name);

    // Remove from ui if exists
    /* istanbul ignore else */
    if (filter && !index) {
      this.visibleFilters$.next([...visibleFilters.filter((o) => o.name !== column.name)]);
    }

    // Remove from store if set
    if (this.service.getFilterValue(column.name) !== undefined) {
      this.service.removeFilter(column.name, index);
    }
  }
}

import { KeyValuePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControlStatus, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { FloatLabelType, MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { NamedFormControl } from '@navigatingart/named-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { FormFieldBaseComponent } from '../form-field-base.component';

export type SelectableEntity = string | Record<string, unknown> | number;

@UntilDestroy()
@Component({
  selector: 'navigatingart-select',
  templateUrl: './select.component.html',
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    TranslateModule,
    MatSelect,
    MatTooltip,
    ReactiveFormsModule,
    MatOption,
    MatError,
    KeyValuePipe,
  ],
})
export class SelectComponent extends FormFieldBaseComponent implements OnInit {
  PRESELECTED_VALUE_ID = '-1';

  displayControl = new NamedFormControl('display');

  @Input()
  override control!: NamedFormControl;

  @Input()
  options!: SelectableEntity[];

  @Input()
  floatLabel: FloatLabelType = 'auto';

  @Input()
  required = false;

  @Input()
  allowReset = false;

  @Input()
  resetValue = null;

  @Input()
  displayFn: (instance: SelectableEntity) => string = (entityString: SelectableEntity) => `${entityString}`;

  updateDisplayValue(value: SelectableEntity): void {
    if (!this.options || (!value && !!value !== false)) return;

    let findPredicate;
    //if value is an object but has no id, select the option with the default id (-1)
    if (value && typeof value === 'object' && !value['id']) {
      findPredicate = (o: Record<string, unknown>) => o['id'] === this.PRESELECTED_VALUE_ID;
    } else {
      // FIXME: Rewrite this line to make it more readable
      findPredicate = (value as Record<string, unknown>)?.['id']
        ? (o: Record<string, unknown>) => o['id'] === (value as Record<string, unknown>)['id']
        : (o: string | number) => o === value;
    }

    const preselected = this.options.find(findPredicate as (o: Record<string, unknown> | (string | number)) => boolean);
    this.displayControl.setValue(preselected, { emitEvent: false });
  }

  override ngOnInit() {
    super.ngOnInit();
    if (this.control.required) {
      this.displayControl.setValidators(Validators.required);
    }

    this.control.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: SelectableEntity) => this.updateDisplayValue(value));

    this.displayControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value: SelectableEntity) => {
      this.control.updateFromSource(value);
      this.control.markAsDirty();
    });

    if (this.control.disabled) {
      this.displayControl.disable();
    }

    this.control.statusChanges.pipe(untilDestroyed(this)).subscribe((controlStatus: FormControlStatus) => {
      if (controlStatus === 'DISABLED') {
        this.displayControl.disable({ emitEvent: false });
      } else if (controlStatus === 'VALID') {
        this.displayControl.enable({ emitEvent: false });
      }
    });

    this.updateDisplayValue(this.control.value);
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatSuffix } from '@angular/material/form-field';
import { FormFieldBaseComponent } from '../form-field-base.component';
import { FormFieldInputComponent } from '../form-field-input/form-field-input.component';

@Component({
  selector: 'navigatingart-form-field-url-input',
  templateUrl: './url-input.component.html',
  styleUrls: ['./url-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [FormFieldInputComponent, MatSuffix],
})
export class UrlInputComponent extends FormFieldBaseComponent implements OnInit {
  override ngOnInit() {
    this.control.setValidators([Validators.pattern(/\S+[^.]\.[^.]\S+/)]);
  }

  generateUrl(): string {
    const enteredValue = this.control.value;

    if (!enteredValue) return '';
    if (enteredValue.match(/^https?:\/\//)) return enteredValue;

    return 'https://' + enteredValue;
  }
}

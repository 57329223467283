import { KeyValuePipe, NgClass } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { FloatLabelType, MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { NamedFormControl } from '@navigatingart/named-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { FormFieldBaseComponent } from '../form-field-base.component';

export interface IEnumDisableSettings<T> {
  disabledOptions: string[];
  disableFn: (instance: T, allowedOptions: T[]) => boolean;
}

export interface IEnumSelectableEntity {
  icon?: string;
  key: string;
  value: string | number;
}

export type enumType = Record<string, unknown>;

export type enumKey = keyof enumType;

@UntilDestroy()
@Component({
  selector: 'navigatingart-enum',
  templateUrl: './enum.component.html',
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    TranslateModule,
    MatSelect,
    ReactiveFormsModule,
    MatSelectTrigger,
    NgClass,
    MatOption,
    MatError,
    KeyValuePipe,
  ],
})
export class EnumComponent extends FormFieldBaseComponent implements OnInit {
  options: IEnumSelectableEntity[] | undefined;

  selectedOption: IEnumSelectableEntity | undefined;

  @Input()
  enum!: enumType;

  @Input()
  iconConfig!: Record<enumKey, string>;

  @Input()
  override control!: NamedFormControl;

  @Input()
  disableSettings!: IEnumDisableSettings<unknown>;

  @Input()
  allowReset = false;

  @Input()
  resetValue = null;

  @Input()
  floatLabel: FloatLabelType = 'auto';

  @Input()
  displayFn: (instance: string) => string = (input) => '' + input;

  override ngOnInit() {
    super.ngOnInit();
    this.filterOptions();
    this.mapEnumToOptions();

    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe({
      next: (value: string | number) => this.updateSelectedValue(value),
    });

    this.updateSelectedValue(this.control.value);
  }

  updateSelectedValue(value: string | number): void {
    const findPredicate = (o: IEnumSelectableEntity) => o.value === value;
    this.selectedOption = (this.options || []).find(findPredicate);
  }

  // Filters the given options so that only enum names are left and no enum values
  filterOptions() {
    Object.keys(this.enum).forEach((key) => {
      Object.keys(this.enum).forEach((key2) => {
        if (key !== key2 && key === this.enum[key2]) {
          delete this.enum[key2];
        }
      });
    });
  }

  // Converts enum object into an array of options of type IEnumSelectableEntity
  mapEnumToOptions(): void {
    const options: IEnumSelectableEntity[] = [];

    Object.keys(this.enum).forEach((key: string) => {
      const option = { key, value: this.enum[key] } as IEnumSelectableEntity;
      const icon = this.iconConfig ? this.iconConfig[key] : undefined;

      if (icon) {
        option.icon = icon;
      }
      options.push(option);
    });
    this.options = options;
  }
}

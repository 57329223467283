<mat-form-field>
  <input
    #chipInput
    matInput
    (matChipInputTokenEnd)="createChip($event)"
    [formControl]="chipInputControl"
    [matAutocomplete]="auto"
    [matChipInputAddOnBlur]="false"
    [matChipInputFor]="chipList"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    [placeholder]="placeholder | translate"
  />

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)" [displayWith]="displayFn">
    @for (subject of autocompleteOptions$ | async; track subject) {
      <mat-option [value]="subject">
        {{ displayFn(subject) }}
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>

<mat-chip-grid #chipList> </mat-chip-grid>

@for (chipValue of control.value; track chipValue; let i = $index) {
  <mat-chip
    [ngClass]="chipWhite ? 'na-chip-white' : 'mat-accent'"
    (removed)="removeChip(i)"
    (click)="onClick($event)"
    [matTooltip]="displayFn(chipValue)"
  >
    <span>{{ displayFn(chipValue) || '&nbsp;' }}</span>
    <mat-icon matChipRemove> clear </mat-icon>
  </mat-chip>
}

<div class="filters">
  @if (visibleFilters$ | async; as visibleFilters) {
    @for (filter of visibleFilters; track filter; let idx = $index) {
      <div class="filter">
        <navigatingart-data-table-filters-input
          class="flex-grow"
          (triggerAction)="service.setFilter($event!)"
          [column]="filter"
          [query]="query"
          [service]="service"
        >
        </navigatingart-data-table-filters-input>
        <div
          class="button-wrapper"
          matTooltip="{{ 'DataTableFiltersComponent.filter.tip' | translate }} '{{ filter.label! | translate }}'"
        >
          <button type="button" mat-flat-button class="na-clear-btn" (click)="onRemoveFilter(filter)">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </div>
      @if ((idx + 1) % 3 === 0 && idx < visibleFilters.length - 1) {
        <div></div>
      }
    }
  }

  @if (columnToggleMenuItems$ | async; as menuItems) {
    <div>
      <button type="button" mat-flat-button class="na-add-btn" [matMenuTriggerFor]="appMenu">
        <i class="icon-add"></i>
      </button>
      <mat-menu #appMenu="matMenu">
        @for (menuItem of menuItems; track menuItem) {
          <mat-option (click)="onAddFilter(menuItem.column)" [disabled]="menuItem.disabled" [value]="menuItem.column">
            {{ menuItem.column.label! | translate }}
          </mat-option>
        }
      </mat-menu>
    </div>
  }
</div>

import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { KeyValuePipe } from '@angular/common';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  FloatLabelType,
  MatError,
  MatFormField,
  MatHint,
  MatLabel,
  MatPrefix,
  MatSuffix,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MarkdownComponent } from 'ngx-markdown';
import { FormFieldBaseComponent } from '../form-field-base.component';

@Component({
  selector: 'navigatingart-form-field-textarea',
  templateUrl: './form-field-textarea.component.html',
  styleUrls: ['./form-field-textarea.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    TranslateModule,
    MatPrefix,
    MatSuffix,
    MarkdownComponent,
    MatInput,
    CdkTextareaAutosize,
    ReactiveFormsModule,
    MatTooltip,
    MatError,
    MatHint,
    KeyValuePipe,
  ],
})
export class FormFieldTextareaComponent extends FormFieldBaseComponent implements OnInit {
  @Input()
  @HostBinding('class.collapsed')
  collapsed: boolean | undefined = undefined;

  /**
   * Manually set the minimum amount of rows for the `textarea` element. Requires `single` to be set to false.
   */
  @Input()
  minRows = 1.5;

  /**
   * Manually set the maximum amount of rows for the `textarea` element. Requires `single` to be set to false.
   */
  @Input()
  maxRows = 100;

  @Input()
  hintEnd!: string;

  @Input()
  hintStart!: string;

  @Input()
  floatLabel: FloatLabelType = 'auto';

  @Input()
  enableMarkdown = false;

  @Input()
  isClickable = true;

  @Input()
  readonly = false;

  active = false;

  activateInput(): void {
    if (this.active || !this.isClickable) {
      return;
    }
    this.active = true;
  }

  onBlur(): void {
    this.active = false;
  }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { EdtfHelper } from '@navigatingart/edtf-helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { EDTFParser } from 'edtf-converter/dist/edtf.parser';
import { Observable, Subscription, tap } from 'rxjs';
import { FormFieldInputComponent } from '../form-field-input/form-field-input.component';
import { ToggleComponent } from '../toggle/toggle.component';

export interface EDTFValue {
  value: string;
  min?: Date;
  max?: Date;
  invalid?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'navigatingart-edtf-input',
  templateUrl: './edtf-input.component.html',
  styleUrls: ['./edtf-input.component.scss'],
  standalone: true,
  imports: [FormFieldInputComponent, MatIconButton, MatTooltip, ToggleComponent, TranslateModule],
})
export class EDTFInputComponent implements OnInit, OnChanges {
  @Input()
  control!: AbstractControl;

  @Input()
  freeTextControl!: AbstractControl;

  @Input()
  useTextFlagControl?: AbstractControl;

  @Input()
  customLabelEdtf?: string;

  @Input()
  customLabelText?: string;

  @Output()
  valueChanged = new EventEmitter<EDTFValue>();

  controlHint$: Observable<string> | undefined;

  toggleChange$: Subscription | null = null;

  validateTextInput: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (this.useTextFlagControl?.value || !control.value) {
      return null;
    }
    return EdtfHelper.textToEdtf(control.value) ? null : { invalidText: true };
  };

  validateEdtf: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    try {
      EDTFParser.parse(control.value);
      return null;
    } catch (error) {
      return {
        invalidEdtf: true,
      };
    }
  };

  ngOnInit() {
    this.control.setValidators(this.validateEdtf);

    this.freeTextControl.setValidators(this.validateTextInput);

    //FIXME NA-2796 markAsTouched is used to trigger the validation manually in case we have corrupt data coming from the backend,
    // this should be removed with the rework of the edtf component
    if (this.control.value) {
      this.control.markAsTouched();
    }
    if (this.freeTextControl.value) {
      this.freeTextControl.markAsTouched();
    }
  }

  //FIXME NA-2465 This valuechange is needed since the validation does not get triggered by the patchValue in AbstractEdit
  ngOnChanges(): void {
    if (this.useTextFlagControl) {
      if (!this.toggleChange$) {
        this.toggleChange$ = this.useTextFlagControl.valueChanges
          .pipe(
            untilDestroyed(this),
            tap(() => this.freeTextControl.updateValueAndValidity()),
          )
          .subscribe();
      }
    } else if (this.toggleChange$) {
      this.toggleChange$.unsubscribe();
    }
  }

  fillEDTFFromText(force = false) {
    if (this.useTextFlagControl?.value) {
      return;
    }
    if (force === false && this.control.value) {
      return;
    }
    const edtf = EdtfHelper.textToEdtf(this.freeTextControl.value);
    if (edtf === null) {
      return this.freeTextControl.updateValueAndValidity();
    }
    this.control.setValue(edtf);
    this.control.markAsDirty();
  }

  renderHint(edtf: string): string | null {
    if (!edtf) {
      return '';
    }
    if (this.control.invalid) {
      return null;
    }
    try {
      return EdtfHelper.edtfToText(edtf);
    } catch (error) {
      return null;
    }
  }
}

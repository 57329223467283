@if (!config.component) {
  @if (config.link) {
    <a
      [routerLink]="config.link(row)"
      [fragment]="config.linkFragment ? config.linkFragment(row) : undefined"
      [target]="config.openLinkInTab ? '_blank' : '_self'"
      [class.na-neutral]="!config.linkFragment"
      [matTooltip]="config.tooltip ? config.tooltip(row) : ''"
    >
      <ng-container *ngTemplateOutlet="cellContent; context: { row: row }"></ng-container>
    </a>
  } @else {
    <ng-container *ngTemplateOutlet="cellContent; context: { row: row }"></ng-container>
  }
  <ng-template #cellContent let-row="row">
    @if (config.image && config.image(row)) {
      <img loading="eager" alt="cell-image" [src]="config.image(row)" />
    } @else {
      <div [matTooltip]="config.tooltip ? config.tooltip(row) : ''" [innerHTML]="displayFn(config, row)"></div>
    }
  </ng-template>
}

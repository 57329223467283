<mat-form-field>
  <mat-label [translate]="label ?? ''"></mat-label>
  <span matPrefix> </span>
  <span matSuffix> </span>
  <input
    type="text"
    [placeholder]="'AutoCompleteObjectComponent.placeholder.text' | translate"
    [attr.aria-label]="label"
    matInput
    #inputControl
    [formControl]="displayControl"
    [matAutocomplete]="auto"
    (focusout)="displayFocusout.emit(this.displayControl.value); $event.stopPropagation()"
    [required]="displayControl.required || required"
  />
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
    @if (createFn && validateDisplayValue()) {
      <mat-option [value]="displayControl.value" (click)="createNewOption()">
        <i class="icon-add"></i>
        <span>{{ displayControl.value }}</span>
      </mat-option>
    }
    @for (option of autocompleteOptions$ | async; track $index) {
      <mat-option [value]="option">
        {{ displayFn(option) }}
      </mat-option>
    }
  </mat-autocomplete>

  @for (errorName of displayControl.errors | keyvalue; track errorName.key) {
    <mat-error [translate]="errorLabel(errorName.key)"></mat-error>
  }

  @if (hintFn) {
    <mat-hint align="start">{{ hintFn(displayControl.value) }}</mat-hint>
  }
</mat-form-field>

/* eslint-disable @typescript-eslint/no-explicit-any */
// tslint:disable-next-line:max-line-length
import { AsyncPipe, NgClass, ViewportScroller } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { MatButton, MatIconAnchor, MatIconButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatIcon } from '@angular/material/icon';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatRadioButton } from '@angular/material/radio';
import { MatSort, MatSortHeader, Sort } from '@angular/material/sort';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
} from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { LoadingIndicatorComponent } from '@navigatingart/ui-tools';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isEqual, uniqBy } from 'lodash-es';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, filter, first, map, pairwise } from 'rxjs/operators';
import {
  DataTableActionPerformed,
  DataTableComponentEntityType,
  DataTableQueryAndStore,
  IDataTableColumnConfig,
  IDataTableColumnGroup,
  IDataTableConfig,
} from '../../data-table.interfaces';
import { DataTableService } from '../../data-table.service';
import { DataTableQuery, DataTableStore, DataTableViewOptions } from '../../data-table.store';
import { DATA_TABLE_SETTINGS, IDataTableModuleSettings } from '../../data-table.token';
import { PaginatorTranslatorService } from '../../paginator-translator.service';
import { DataTableCellComponent } from '../data-table-cells/data-table-cell.component';
import { DataTableColumnMenuComponent } from '../data-table-column-menu/data-table-column-menu.component';
import { DataTableFiltersComponent } from '../data-table-filters/data-table-filters.component';

@UntilDestroy()
@Component({
  selector: 'navigatingart-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    LoadingIndicatorComponent,
    MatRadioButton,
    MatCheckbox,
    DataTableFiltersComponent,
    MatButton,
    MatTooltip,
    NgClass,
    MatPaginator,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    DataTableColumnMenuComponent,
    MatIconButton,
    MatIcon,
    MatIconAnchor,
    RouterLink,
    MatSortHeader,
    DataTableCellComponent,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    AsyncPipe,
    TranslateModule,
  ],
  providers: [
    {
      provide: DATA_TABLE_SETTINGS,
      useValue: {
        AUTOCOMPLETE_DEBOUNCE_TIME: 250,
        DATE_QUERY_FORMAT: 'YYYY-MM-DD',
      },
    },
    {
      provide: MatPaginatorIntl,
      useClass: PaginatorTranslatorService,
      deps: [TranslateService],
    },
  ],
})
export class DataTableComponent<T extends DataTableComponentEntityType> implements OnInit, OnDestroy {
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private viewportScroller = inject(ViewportScroller);
  readonly dataTableSettings = inject<IDataTableModuleSettings>(DATA_TABLE_SETTINGS);

  @Input()
  config!: IDataTableConfig<T>;

  @Input()
  selectableRow? = true;

  @HostBinding('class.na-disabled')
  @Input()
  disabled? = false;

  @Input()
  restoreScroll = false;

  @Output()
  initialized = new EventEmitter<DataTableQueryAndStore<T>>();

  @Output()
  selectionChange = new EventEmitter<T[]>();

  @Output()
  actionPerformed = new EventEmitter<DataTableActionPerformed<T>>();

  @Output()
  afterCreate = new EventEmitter<any>();

  @Output()
  afterEdit = new EventEmitter<any>();

  @HostBinding('class')
  hostCSSClass: string | undefined;

  selectedRecents = new BehaviorSubject<T[]>([]);

  activeElements: unknown[] = [];

  selectedRow: any;

  multiSelected: any[] = [];

  pageSizeOptions = [25, 50, 100, 1000];

  selectedToggleColumns: string[] = [];

  showColumnsMenu = true;

  query!: DataTableQuery<T>;
  store!: DataTableStore<T>;
  service!: DataTableService<T>;

  viewOptions = DataTableViewOptions;

  ngOnInit() {
    if (!this.config) {
      throw new Error(`Missing @Input() config`);
    }

    this.pageSizeOptions = this.config.pageSizeOptions ?? this.pageSizeOptions;
    this.store = new DataTableStore<T>(this.config);
    this.query = new DataTableQuery<T>(this.store, this.config);
    this.service = new DataTableService<T>(this.store, this.query, this.config);

    this.hostCSSClass = this.config.name.toLowerCase();
    this.initialized.emit({
      store: this.store,
      query: this.query,
      service: this.service,
    });

    //never persist active entites so we reset the state on init
    this.service.setActive([]);

    // Reset to first page on filter change
    this.query.renderState$
      .pipe(
        untilDestroyed(this),
        map((renderState) => renderState.filters),
        pairwise(),
        filter(([prev, curr]) => !isEqual(curr, prev)),
        debounceTime(this.dataTableSettings.AUTOCOMPLETE_DEBOUNCE_TIME),
      )
      .subscribe({
        next: () => this.service.updatePage(0),
      });

    this.query.renderState$
      .pipe(
        untilDestroyed(this),
        debounceTime(this.dataTableSettings.AUTOCOMPLETE_DEBOUNCE_TIME),
        distinctUntilChanged((prev, curr) => {
          prev = { ...prev, lastScroll: undefined };
          curr = { ...curr, lastScroll: undefined };
          return isEqual(prev, curr);
        }),
      )
      .subscribe(() => this.service.get());

    if (this.restoreScroll) {
      combineLatest([this.query.entities$, this.query.renderState$])
        .pipe(
          filter(([entities]) => !!entities.length),
          first(),
          delay(500),
        )
        .subscribe({
          next: ([, renderState]) => {
            this.viewportScroller.scrollToPosition(renderState.lastScroll || [0, 0]);
          },
        });
    }

    combineLatest([this.query.activeElements$, this.selectedRecents])
      .pipe(untilDestroyed(this), debounceTime(100))
      .subscribe({
        next: ([active, recents]) => {
          this.activeElements = active;
          const merged = uniqBy([...active, ...recents], 'id');
          this.selectionChange.emit(merged);
        },
      });
  }

  ngOnDestroy(): void {
    if (this.store?.config.resettable) {
      this.store.destroy();
    }
  }

  /* istanbul ignore next */
  onPaginate(pagination: PageEvent): void {
    this.service.updatePagination(pagination);
  }

  /* istanbul ignore next */
  onSortChange(sort: Sort) {
    this.service.updateSort(sort);
  }

  /* istanbul ignore next */
  navigateToRowLink(element: T) {
    if (this.config.rowLink) {
      if (typeof this.config.rowLink(element) === 'string') {
        const url = window.location.origin + this.config.rowLink(element);
        window.open(url, '_blank');
      } else {
        this.router.navigate(this.config.rowLink(element) as (string | number)[], {
          relativeTo: this.activatedRoute,
          fragment: this.config.rowLinkFragment ? this.config.rowLinkFragment(element) : undefined,
        });
      }
    }
  }

  /* istanbul ignore next */
  groupForColumn(c: IDataTableColumnConfig<T>): IDataTableColumnGroup | undefined {
    if (!this.config.groups) return undefined;
    return this.config.groups.find((group: IDataTableColumnGroup) => group.columns.includes(c.name));
  }

  /* istanbul ignore next */
  isFirstOfGroup(c: IDataTableColumnConfig<T>): boolean {
    const group = this.groupForColumn(c);
    if (!group) return false;

    const groupColumnConfig =
      this.config.columns.filter((o: IDataTableColumnConfig<T>) => group.columns.includes(o.name)) || [];

    const visibleGroupColumns = [...groupColumnConfig]
      .sort((a: IDataTableColumnConfig<T>, b: IDataTableColumnConfig<T>) => {
        if (!this.config.displayOrder) {
          return 0;
        }
        return this.config.displayOrder.indexOf(a.name) - this.config.displayOrder.indexOf(b.name);
      })
      .map((val: IDataTableColumnConfig<T>) => val.name);

    if (!visibleGroupColumns) return false;

    return visibleGroupColumns[0] === c.name;
  }

  /* istanbul ignore next */
  groupLabel(c: IDataTableColumnConfig<T>): string | undefined {
    const group = this.groupForColumn(c);
    return group ? group.label : undefined;
  }

  /* istanbul ignore next */
  isGrouped(c: IDataTableColumnConfig<T>): boolean {
    if (!this.config.groups) return false;
    const groupedColumns = this.config.groups.reduce((acc, cur) => acc.concat(cur.columns), [] as string[]);
    return groupedColumns.includes(c.name);
  }

  /* istanbul ignore next */
  onColumnMenuChanged(visibleColumns: string[]): void {
    this.service.setVisibleColumns(visibleColumns);
  }

  onViewModeChanged(viewMode: DataTableViewOptions) {
    this.service.updateViewMode(viewMode);
  }

  /* istanbul ignore next */
  onActiveChanged(row: T): void {
    //dont allow the selection of a disabled row
    if (this.config.disableRowSelection && this.config.disableRowSelection(row)) return;
    this.selectedRow = row.id;
    if (this.config.selection === 'single') {
      this.service.setActive([row]);
      this.selectedRecents.next([]);
    } else if (this.config.selection === 'multi') {
      if (!this.isActive(row)) {
        this.service.addActive([row]);
      } else {
        this.service.removeActive([row]);
      }
    }
  }

  onActiveChangedRecents(row: T): void {
    if (this.config.selection === 'single') {
      this.selectedRecents.next([row]);
      this.service.setActive([]);
    } else if (this.config.selection === 'multi') {
      if (!this.isRecentSelected(row)) {
        this.selectedRecents.next([...this.selectedRecents.value, row]);
      } else {
        this.selectedRecents.next(this.selectedRecents.value.filter((o) => o.id !== row.id));
      }
    }
  }

  isRecentSelected(row: T): boolean {
    return this.selectedRecents.value.map((o) => o.id).includes(row.id);
  }

  isActive(row: T): boolean {
    return this.activeElements.some((searchElement: T | unknown) => (searchElement as T).id === row.id);
  }

  /* istanbul ignore next */
  datasourceTrackById(idx: number, o: any): string {
    return `${idx}-${o[this.config.idKey ?? 'id']}-${o.createdAt}`;
  }

  rowClass(row: T): string | string[] | Set<string> | undefined {
    if (!this.config.rowClass) {
      return undefined;
    }
    return this.config.rowClass(row);
  }

  @HostListener('window:scroll')
  onScroll() {
    const lastScroll = this.viewportScroller.getScrollPosition();
    this.service._updateRenderState({ lastScroll });
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentType } from '@angular/cdk/overlay';
import { Type } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { AbstractDataTableFilterComponent } from './components/data-table-filters/abstract-data-table-filter.component';
import { DataTableFilterType } from './data-table.constants';
import { DataTableService } from './data-table.service';
import { DataTableFiltersState, DataTableQuery, DataTableStore, DataTableUIState } from './data-table.store';

export interface IDataTableSearchOption<T = unknown> {
  value: T;
  translatable: string;
  label?: {
    translatable?: string;
    class?: string;
    style?: string;
  };
}

export type DataTableActionConfig<T> = {
  label?: string;
  icon?: string;
  click?: (row: T) => void;
  link?: (row: T) => unknown[];
  emit?: string;
  tooltip?: () => string;
};

export interface IDataTableGridElementConfig<T> {
  image: (row: T) => SafeUrl | null;
  heading?: (row: T) => string;
  content: (row: T) => string[];
}

export interface IDataTableColumnConfig<T extends DataTableComponentEntityType> {
  // Column name
  name: string;
  // Column name for API operations, default is .name
  queryParamName?:
    | string
    | ((searchValue: unknown, config: IDataTableColumnConfig<T>, ui: DataTableUIState<unknown>) => string);
  // Column label
  label?: string;
  // Render cell content via method
  content?: ((row: T) => string) | string;
  // Allow column sorting
  sortable?: boolean;
  // API column sort parameter name
  sortParamName?: string;
  // Transform sort params from UI to API
  sortTransform?: (sort: Sort) => unknown;
  // Image url of column if image
  image?: (row: T) => SafeUrl | null;
  // Link of column
  link?: (row: T) => (string | number | undefined)[];
  // Link fragment of column
  linkFragment?: (row: T) => string | undefined | null;
  // Flag to open links in new tab
  openLinkInTab?: boolean;
  // Cell render component
  component?: Type<unknown>; // ! Update to type!!!
  filter?: IDataTableColumnConfigFilter<T> | IDataTableColumnConfigComplexFilter<T, unknown> | 'auto';
  complexFilterType?: boolean;
  // Hide column for selection in column menu
  hideInColumnMenu?: boolean;
  //Show Tooltip onHover
  tooltip?: (row: T) => string;
}

export interface IDataTableHeaderConfig {
  showFilters?: boolean;
  showHeaderCells?: boolean;
  showPagination?: boolean;
}

export interface DataTableActionPerformed<T> {
  action: string;
  row: T;
}

export enum DataTableDefaultActions {
  EDIT = 'edit',
  DELETE = 'delete',
  NAVIGATE = 'navigate',
}

export interface IDataTableColumnConfigFilter<T extends DataTableComponentEntityType> {
  type?: DataTableFilterType;
  optionsFn?: (search: string | undefined) => Observable<IDataTableSearchOption[]>;
  filterComponent?: ComponentType<AbstractDataTableFilterComponent<T>>;
  display?: (filters: any) => string | string[];
  // transform and filter for only necessary data when sending to store
  transform?: (search: DataTableFiltersSearch<T>, filters: any) => unknown;
  // transform data in store for api
  searchParamTransform?: (...any: any[]) => any;
}

export interface IDataTableColumnConfigComplexFilter<T extends DataTableComponentEntityType, ET>
  extends IDataTableColumnConfigFilter<T> {
  dropdownEnum: ET;
  dropdownEnumDefault: string;
}

export interface DataTableFiltersSearch<T extends DataTableComponentEntityType, VT = unknown> {
  column: IDataTableColumnConfig<T>;
  value: VT;
}

export interface IDataTableQueryResponsePagination {
  total: number;
  pageSize: number;
  page: number;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
}

export interface IDataTableQueryResponse<T> {
  pagination: IDataTableQueryResponsePagination;
  data: Array<T>;
  sorting?: {
    active: Array<unknown>;
    available: Array<string>;
  };
}

export interface IDataTableConfig<T extends DataTableComponentEntityType> {
  // Config for actions column
  actions?: DataTableActionConfig<T>[];
  // name of datatable
  name: string;
  // Definition for table columns
  columns: IDataTableColumnConfig<T>[];
  // Show/hide column select menu
  columnMenu?: boolean;
  // Show/hide table header
  header?: IDataTableHeaderConfig;
  // Link of row
  rowLink?: (element: T) => (string | number)[] | string;
  // Dynamic CSS class for row
  rowClass?: (element: T) => string | string[] | Set<string> | undefined;
  // Link fragment of row
  rowLinkFragment?: (row: T) => string;
  // Dont allow selection of row
  disableRowSelection?: (row: T) => boolean;
  // Dont allow selection of row
  selectInputTooltip?: (row: T) => string | undefined;
  // Show/hide table paging
  paginatable?: boolean;
  // If unset, defaults to 25
  defaultPageSize?: number;
  // Selection type
  selection?: 'single' | 'multi' | undefined;
  selectionLimit?: number;
  // Make header sticky
  stickyHeader?: boolean;
  displayOrder?: string[];
  filterOrder?: string[];
  visibleColumns?: string[];
  initialSort?: Sort;
  initialFilters?: DataTableFiltersState;
  queryFn: (config: IDataTableConfig<T>, ui: DataTableUIState<T>) => Observable<IDataTableQueryResponse<T>>;
  groups?: IDataTableColumnGroup[];
  idKey?: string;
  showRecents?: boolean;
  renderRecent?: (element: T) => string | undefined | null | HTMLElement;
  recentLimit?: number; // Defaults to 5
  gridEnabled?: boolean;
  gridElementConfig?: IDataTableGridElementConfig<T>;
  splitRequest?: boolean;
  pageSizeOptions?: number[];
}

export interface IDataTableFilterDateMinMaxValue {
  min: string;
  max: string;
  text?: string;
  edtf?: string;
}

export interface IDataTableColumnGroup {
  columns: string[];
  label: string;
}

export interface DataTableEntityService<T extends DataTableComponentEntityType> {
  get(): void;
  setVisibleColumns(visibleColumns: string[]): void;
  updatePagination(pagination: PageEvent): void;
  updateSort(sort: Sort): void;
  getColumnConfig(name: string): IDataTableColumnConfig<T> | undefined;
  getFilters(): DataTableFiltersState;
  clearFilters(): void;
  removeFilter(name: string): void;
  setFilter({ column, value }: DataTableFiltersSearch<T>): void;
  displayFilterValue(column: IDataTableColumnConfig<T>): string | string[] | undefined;
}

export interface DataTablePagination {
  total: number;
  pageSize: number;
  page: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface DataTableQueryAndStore<T extends DataTableComponentEntityType> {
  store: DataTableStore<T>;
  service: DataTableService<T>;
  query: DataTableQuery<T>;
}

export interface DataTableQueryGenerator<T extends DataTableComponentEntityType> {
  generateQuery(config: IDataTableConfig<T>, ui: DataTableUIState<T>): unknown;
}

export interface DataTableComponentEntityType {
  id?: number | string | null;
  textKey?: string;
}

export type TestDataTableType = DataTableComponentEntityType & {
  test?: string;
};

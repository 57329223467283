// tslint:disable-next-line:max-line-length
import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewContainerRef,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { SafeUrl } from '@angular/platform-browser';
import { RouterLink } from '@angular/router';
import { get, isFunction, isString } from 'lodash-es';
import { DataTableComponentEntityType, IDataTableColumnConfig } from '../../data-table.interfaces';
import { DataTableService } from '../../data-table.service';

type ISubComponent<T extends DataTableComponentEntityType> = {
  row: T;
  config: unknown;
  service: DataTableService<T>;
};

@Component({
  selector: 'navigatingart-data-table-cell',
  templateUrl: './data-table-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [RouterLink, MatTooltip, NgTemplateOutlet],
})
export class DataTableCellComponent<T extends DataTableComponentEntityType> implements OnInit, OnDestroy, OnChanges {
  private viewContainerRef = inject(ViewContainerRef);
  private componentFactoryResolver = inject(ComponentFactoryResolver);

  @Input()
  config!: IDataTableColumnConfig<T>;

  @Input()
  row!: T;

  @Input()
  service!: DataTableService<T>;

  componentRef: ComponentRef<ISubComponent<T>> | undefined;

  ngOnInit(): void {
    /* istanbul ignore else */
    if (this.config.component) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.config.component);

      this.viewContainerRef.clear();
      this.componentRef = this.viewContainerRef.createComponent(componentFactory) as ComponentRef<ISubComponent<T>>;
      this.componentRef.instance.row = this.row;
      this.componentRef.instance.config = this.config;
      this.componentRef.instance.service = this.service;
    }
  }

  ngOnChanges(): void {
    /* istanbul ignore else */
    if (this.componentRef) {
      this.componentRef.instance.row = this.row;
      this.componentRef.instance.config = this.config;
      this.componentRef.instance.service = this.service;
    }
  }

  displayFn(column: IDataTableColumnConfig<T>, element: T): string | SafeUrl {
    /* istanbul ignore else  */
    if (isString(column.content)) return get(element, column.content);
    /* istanbul ignore else  */
    if (isFunction(column.content)) return column.content(element);
    /* istanbul ignore else  */
    if (isFunction(column.image)) return column.image(element) as SafeUrl;
    /* istanbul ignore else  */
    if (!column.content) return get(element, column.name);
    throw new Error(`Invalid content type ${column.content} for column ${column}`);
  }

  ngOnDestroy(): void {
    if (this.componentRef) this.componentRef.destroy();
  }
}

import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { omit, uniqBy } from 'lodash-es';
import { catchError, first, tap } from 'rxjs/operators';
import {
  DataTableComponentEntityType,
  DataTableEntityService,
  DataTableFiltersSearch,
  IDataTableColumnConfig,
  IDataTableColumnConfigFilter,
  IDataTableConfig,
  IDataTableQueryResponse,
} from './data-table.interfaces';
import {
  DataTableFiltersState,
  DataTableQuery,
  DataTableRenderState,
  DataTableStore,
  DataTableUIState,
  DataTableViewOptions,
} from './data-table.store';

export class MissingConfigError extends Error {}

export class DataTableService<T extends DataTableComponentEntityType> implements DataTableEntityService<T> {
  constructor(
    private store: DataTableStore<T>,
    private query: DataTableQuery<T>,
    private dataTableConfig: IDataTableConfig<T>,
  ) {}
  removeActive(args: T[]) {
    this.store.update((state) => {
      return {
        ui: {
          ...state.ui,
          active: state.ui.active.filter((value: T) => !args.find((c: T) => c.id === value.id)),
        },
      };
    });
  }

  addActive(args: T[]) {
    this.store.update((state) => {
      return {
        ui: {
          ...state.ui,
          active: uniqBy([...state.ui.active, ...args], 'id'),
        },
      };
    });
  }

  setActive(args: T[]) {
    this.store.update((state) => {
      return {
        ui: {
          ...state.ui,
          active: [...args],
        },
      };
    });
  }

  get(): void {
    this.store.setLoading(true);
    const ui = this.store.getValue().ui;
    this.dataTableConfig
      .queryFn(this.dataTableConfig, ui)
      .pipe(
        catchError((error) => {
          this.store.setError(error);
          this.store.setLoading(false);
          throw error;
        }),
        tap((result: IDataTableQueryResponse<T>) => {
          const defaultPagination = {
            total: 0,
            pageSize: 0,
            page: 0,
            hasNextPage: false,
            hasPreviousPage: false,
          };
          const pagination = result.pagination
            ? result.pagination
            : this.store.getValue().response
              ? this.store.getValue().response.pagination
              : defaultPagination;
          this.store.setError(null);
          this.store.set(result.data);
          this.store.update({
            response: {
              pagination,
            },
          });
        }),
      )
      .subscribe();
  }

  _updateUI(changes: Partial<DataTableUIState<T>>) {
    this.store.update((state) => ({
      ui: {
        ...state.ui,
        ...changes,
      },
    }));
  }

  _updateRenderState(changes: Partial<DataTableRenderState>) {
    this.store.update((state) => ({
      ui: {
        ...state.ui,
        renderState: {
          ...state.ui.renderState,
          ...changes,
        },
      },
    }));
  }

  updatePage(pageIndex: number) {
    this.store.update((state) => ({
      ui: {
        ...state.ui,
        renderState: {
          ...state.ui.renderState,
          pagination: {
            ...state.ui.renderState.pagination,
            pageIndex,
          },
        },
      },
    }));
  }

  updateViewMode(viewMode: DataTableViewOptions) {
    this._updateUI({ viewMode });
  }

  updatePagination(pagination: PageEvent) {
    this._updateRenderState({ pagination });
  }

  setVisibleColumns(visibleColumnNames: string[]): void {
    this._updateUI({ visibleColumnNames });
  }

  updateSort(sort: Sort) {
    // No sort direction indicates removed sort
    if (!sort.direction) {
      this._updateRenderState({ sort: undefined });
    } else {
      this._updateRenderState({ sort });
    }
  }

  getColumnConfig(name: string): IDataTableColumnConfig<T> | undefined {
    return this.dataTableConfig.columns.find((column: IDataTableColumnConfig<T>) => column.name === name);
  }

  getFilters(): DataTableFiltersState {
    return this.store.getValue().ui.renderState.filters;
  }

  clearFilters(): void {
    this._updateRenderState({ filters: undefined });
  }

  setSelectionMode(mode: 'single' | 'multi' | null) {
    this.query.visibleColumnNames$.pipe(first()).subscribe({
      next: (visibleColumns: string[]) => {
        let cols: string[] = [];
        if (mode) {
          if (!visibleColumns.includes('selection')) {
            cols = ['selection', ...visibleColumns];
          }
        } else {
          cols = visibleColumns.filter((c) => c !== 'selection');
        }
        this.dataTableConfig.selection = mode as 'single' | 'multi';
        this.setVisibleColumns(cols);
      },
    });
  }

  /* istanbul ignore next */
  removeFilter(name: string, index?: number): void {
    let modifiedFilter: unknown[] | undefined = undefined;
    if (index !== undefined) {
      const oldFilter = this.getFilterValue(name);
      if (!Array.isArray(oldFilter)) {
        throw new Error(`Cannot remove filter index ${index} from non-array filter`);
      }
      if (!oldFilter[index]) {
        throw new Error(`Cannot remove non-existing ${index} from array filter`);
      }
      modifiedFilter = oldFilter.filter((o, idx) => idx !== index);
    }
    this.store.update((state) => ({
      ui: {
        ...state.ui,
        renderState: {
          ...state.ui.renderState,
          filters:
            index === undefined
              ? omit(state.ui.renderState.filters, name)
              : { ...state.ui.renderState.filters, [name]: modifiedFilter },
        },
      },
    }));
  }

  getFilterValue(name: string): unknown | undefined {
    const filters = this.getFilters();
    if (filters && filters[name] !== undefined) {
      return filters[name];
    }
    return undefined;
  }

  setFilterByName(name: string, value: unknown): void {
    const column = this.getColumnConfig(name);
    if (column) {
      this.setFilter({
        column,
        value,
      });
    }
  }

  setFilter({ column, value }: DataTableFiltersSearch<T>): void {
    if (value !== undefined) {
      const transformFn = (column.filter as IDataTableColumnConfigFilter<T>)?.transform;
      if (transformFn) {
        value = transformFn({ column, value }, this.getFilters());
      }
    }

    // Remove filter when set to undefined or ''
    if (!value === null || value === undefined || value === '') {
      return this.removeFilter(column.name);
    }

    this.store.update((state) => ({
      ui: {
        ...state.ui,
        renderState: {
          ...state.ui.renderState,
          filters: { ...state.ui.renderState.filters, [column.name]: value },
        },
      },
    }));
  }

  /* istanbul ignore next */
  displayFilterValue(column: IDataTableColumnConfig<T>): string | string[] | undefined {
    // Fetch filter value
    const value = this.getFilterValue(column.name);

    // Return undefined if filter value is falsy
    if (!value) return undefined;

    // Get filter display fn
    const filterDisplayFn = (column.filter as IDataTableColumnConfigFilter<T>)?.display;
    // Return if display fn returns result
    if (filterDisplayFn) return filterDisplayFn(value);

    // Apply content display fn if no filter display fn available or without result
    if (typeof column.content === 'function') {
      return column.content({ [column.name]: value } as unknown as T);
    }
    return value as string;
  }

  updateRecents(recentEntities?: T[]) {
    this.store.update((state) => {
      const newItems = recentEntities?.length ? recentEntities : this.query.getActive();
      const newItemsIds = newItems.map((o) => o.id);
      //in case the new the recents and new items have the similar items, remove those items from the recents and push them again so they appear at the bottom;
      const recents = [...state.ui.recents].filter((resource) => !newItemsIds.includes(resource.id));
      newItems.forEach((o) => {
        recents.push(o);
      });
      return {
        ui: {
          ...state.ui,
          recents: recents.slice(-(this.dataTableConfig.recentLimit || 5)),
        },
      };
    });
  }
}

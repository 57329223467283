import { FORM_FIELD_MODULE_SETTINGS } from './form-field.token';

export const FormFieldProviders = [
  {
    provide: FORM_FIELD_MODULE_SETTINGS,
    useValue: {
      isProduction: false,
    },
  },
];

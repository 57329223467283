import { AsyncPipe, KeyValuePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, viewChild } from '@angular/core';
import { AbstractControl, FormControlStatus, ReactiveFormsModule, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { MatError, MatFormField, MatHint, MatLabel, MatPrefix, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NamedFormControl } from '@navigatingart/named-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { FormFieldBaseComponent } from '../form-field-base.component';

@UntilDestroy()
@Component({
  selector: 'navigatingart-autocomplete-object',
  templateUrl: './autocomplete-object.component.html',
  styleUrls: ['./autocomplete-object.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    TranslateModule,
    MatPrefix,
    MatSuffix,
    MatInput,
    ReactiveFormsModule,
    MatAutocompleteTrigger,
    MatAutocomplete,
    MatOption,
    MatError,
    MatHint,
    AsyncPipe,
    KeyValuePipe,
  ],
})
export class AutocompleteObjectComponent extends FormFieldBaseComponent implements OnInit {
  displayControl = new NamedFormControl('display');

  @Input()
  fetchFn!: (offset?: number, limit?: number, searchString?: string) => Observable<Record<string, unknown>[]>;

  @Input()
  displayFn!: (instance: Record<string, unknown>) => string;

  @Input()
  hintFn?: (instance: Record<string, unknown>) => string;

  @Input()
  required = false;

  @Input()
  onlyAutocomplete? = true;

  @Input()
  createFn?: (value: string) => Observable<Record<string, unknown>>;

  @Input()
  isListeningToOutsideChange = false;

  @Output()
  displayFocusout = new EventEmitter<Record<string, unknown> | string>();

  autocompleteOptions$?: Observable<Record<string, unknown>[]>;

  optionsToValidate: unknown[] = [];

  inputControl = viewChild<ElementRef<HTMLInputElement>>('inputControl');

  requiredValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const empty =
      control.value == null || control.value.length === 0 || (!control.value.id && this.onlyAutocomplete === true);
    return empty ? { required: true } : null;
  };

  onlyAutocompleteValidator(control: AbstractControl): ValidationErrors | null {
    // Always assume initial value is valid
    if (control.pristine) {
      return null;
    }

    if (this.optionsToValidate === null || this.optionsToValidate.length === 0) {
      return { userInput: true };
    } else {
      if (typeof this.optionsToValidate[0] === 'object') {
        //displayControl.value condition is needed, as sometimes one of the controls returns string and not an object
        return typeof control.value === 'object' || typeof this.displayControl.value === 'object'
          ? null
          : { userInput: true };
      } else if (typeof this.optionsToValidate[0] === 'string') {
        return this.optionsToValidate.includes(control.value) ? null : { userInput: true };
      } else {
        return null;
      }
    }
  }

  override ngOnInit(): void {
    super.ngOnInit();

    if (this.control.value) {
      this.displayControl.setValue(this.control.value, { emitEvent: false });
    }

    if (this.control.disabled) {
      this.displayControl.disable();
    }

    if (this.required) {
      this.displayControl.addValidators([this.requiredValidator.bind(this)]);
    }

    if (this.onlyAutocomplete) {
      this.displayControl.addValidators([this.onlyAutocompleteValidator.bind(this)]);
      this.control.addValidators([this.onlyAutocompleteValidator.bind(this)]);
    }

    this.control.statusChanges.pipe(untilDestroyed(this)).subscribe((controlStatus: FormControlStatus) => {
      if (controlStatus === 'DISABLED') {
        this.displayControl.disable({ emitEvent: false });
      } else if (controlStatus === 'VALID') {
        this.displayControl.enable({ emitEvent: false });
      }
    });

    this.autocompleteOptions$ = this.displayControl.valueChanges.pipe(
      untilDestroyed(this),
      startWith(''),
      filter((input) => typeof input === 'string'),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((searchString: string) => this.fetchFn(0, 50, searchString)),
      tap((options) => (this.optionsToValidate = options)),
      shareReplay(),
    );

    this.displayControl.valueChanges
      .pipe(
        untilDestroyed(this),
        filter(() => this.displayControl.valid),
      )
      .subscribe({
        next: (value: Record<string, unknown>) => {
          this.control.markAsDirty();
          this.control.markAsTouched();
          this.control.updateFromSource(value);
          if (!value) {
            this.control.reset();
          }
        },
      });

    //FIXME This is just a quick fix since this value change messes up the agent create, a better solution is wanted!
    if (this.isListeningToOutsideChange) {
      this.control.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
        if (!value) {
          this.displayControl.reset(null, { emitEvent: false });
        } else {
          this.displayControl.updateFromSource(value, { emitEvent: false });
        }
      });
    }
  }

  createNewOption() {
    if (this.createFn) {
      this.createFn(this.displayControl.value).subscribe({
        next: (savedObject) => {
          this.displayControl.setValue(savedObject);
          this.optionsToValidate.push(savedObject);
          this.control.updateValueAndValidity();
          this.displayControl.updateValueAndValidity();
        },
      });
    }
  }

  validateDisplayValue() {
    return (
      this.displayControl.value &&
      typeof this.displayControl.value === 'string' &&
      !this.optionsToValidate.includes(this.displayControl.value)
    );
  }

  focusInput() {
    this.inputControl()?.nativeElement.focus();
  }

  override clear() {
    super.clear();
    this.displayControl.reset();
  }
}
